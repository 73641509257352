<template>
  <nav v-if="nav" id="adminDesktopNav" :class="navEnlarge && 'navEnlarge'">
    <img v-if="navEnlarge" src="@/assets/logo-wide.png" alt="logo" id="logo" />
    <img v-else src="@/assets/logo-clear.png" alt="logo" id="logo" />
    <router-link to="/" v-if="bookings"><i class="fa-solid fa-calendar-days"></i>
      <span>Bookings</span></router-link>
    <router-link to="/messages"><i class="fa fa-paper-plane"></i>
      <span>Messages</span></router-link>
    <router-link to="/times" v-if="bookings"><i class="fa-regular fa-calendar"></i>
      <span>Scheduling</span></router-link>
    <router-link to="/prices" v-if="bookings"><i class="fa fa-pound-sign"></i>
      <span>Pricing</span></router-link>
    <router-link to="/analytics" v-if="bookings"><i class="fa fa-chart-line"></i>
      <span>Analytics</span></router-link>
    <router-link to="/users" v-if="bookings"><i class="fa fa-users"></i>
      <span>Customers</span></router-link>
    <router-link v-if="settings.hasOnDemand" to="/on-demand"><i class="fa fa-play"></i>
      <span>On Demand</span></router-link>
    <router-link v-if="settings.hasBlog" to="/blogs"><i class="fa fa-newspaper"></i>
      <span>Blog</span></router-link>
    <router-link to="/channels" v-if="!bookings"><i class="fa-regular fa-message"></i>
      <span>Channels</span></router-link>
    <router-link to="/settings"><i class="fa fa-gear"></i>
      <span>Setup</span></router-link>
    <a href="mailto:contact@bookedsolid.co.uk" v-if="!bookings"><i class="fa fa-life-ring"></i>
      <span>Get Help</span></a>

    <i class="fa" :class="navEnlarge ? 'fa-chevron-left' : 'fa-chevron-right'" id="openNav"
      @click="toggleNavEnlarge"></i>
    <i class="fas fa-info-circle" id="openInfo" @click="toggleInfoTab"></i>
    <div v-if="showInfo" id="infoTab">
      <a href="https://guide.bookedsolid.co.uk" target="_blank"><i class="fas fa-book-open"></i> Setup Guide</a>
      <a href="mailto:contact@bookedsolid.co.uk" target="_blank"><i class="fas fa-life-ring"></i> Get Help</a>
    </div>
  </nav>
  <nav v-if="mobileNav && authed" id="adminMobileNav">
    <div id="mobileAdminNavBar">
      <i class="fa fa-caret-down" id="openNav" :class="!showNav && 'active'" @click="showNav = !showNav"></i>
      <i class="fa fa-caret-up" id="closeNav" :class="showNav && 'active'" @click="showNav = !showNav"></i>
    </div>
    <div id="mobileAdminNavDropdown" :class="showNav && 'dropdownOpen'">
      <router-link @click="showNav = false" to="/"><img src="@/assets/logo.png" alt="logo" id="logo" />
        Bookings</router-link>
      <router-link @click="showNav = false" to="/messages"><i class="fa fa-paper-plane"></i> Messages</router-link>
      <router-link @click="showNav = false" to="/times"><i class="fa fa-calendar"></i> Scheduling</router-link>
      <router-link @click="showNav = false" to="/prices"><i class="fa fa-pound-sign"></i> Pricing</router-link>
      <router-link @click="showNav = false" to="/analytics"><i class="fa fa-chart-line"></i>
        Analytics</router-link>
      <router-link @click="showNav = false" to="/users"><i class="fa fa-users"></i> Customers</router-link>
      <router-link @click="showNav = false" v-if="settings.hasOnDemand" to="/on-demand"><i class="fa fa-play"></i>
        On-Demand</router-link>
      <router-link @click="showNav = false" v-if="settings.hasBlog" to="/blogs"><i class="fa fa-newspaper"></i>
        Blog</router-link>
      <router-link @click="showNav = false" to="/settings"><i class="fa fa-gear"></i><span>Setup</span></router-link>
    </div>
  </nav>

  <router-view class="router-view" :class="navEnlarge && 'navEnlarge'" :website="website" />
</template>

<script>
import { getClientSettings } from '@/app/admin-routes';

export default {
  name: 'App',
  data() {
    return {
      authed: false,
      admin: true,
      nav: true,
      email: localStorage.email,
      mobileNav: false,
      showNav: false,
      navEnlarge: localStorage.navEnlarge == 'true' ? true : false,
      bookings: true,
      settings: {
        integration: 'bookedsolid',
        name: '',
        logo: '',
        colourP: '',
        colourS: '',
        colourT: '',
        text: '',
        hasBookings: false,
        hasBlog: false,
        hasAI: false,
        hasOnDemand: false,
      },
      showInfo: false,
    };
  },
  methods: {
    async getSettings() {
      const settings = await getClientSettings();
      if (!settings) {
        localStorage.removeItem('settings');
        document.documentElement.style.setProperty('--colourP', '#0074d9');
        document.documentElement.style.setProperty('--colourS', '#7fdbff');
        document.documentElement.style.setProperty('--colourT', '#39cccc');
        document.documentElement.style.setProperty('--text', '#2c3e50');
        return;
      }
      localStorage.setItem('settings', JSON.stringify(settings));
      this.settings = settings;
      // Apply the settings
      this.bookings = settings.integration == 'bookedsolid' ? true : false;
      document.documentElement.style.setProperty('--colourP', settings.colourP || '#2596BE');
      document.documentElement.style.setProperty('--colourS', settings.colourS || '#2596BE');
      document.documentElement.style.setProperty('--colourT', settings.colourT || '#2562BE');
      document.documentElement.style.setProperty('--text', settings.text || '#2c3e50');
      if (settings.logo) {
        // document.getElementById('logo').src = settings.logo;
        const favicon = document.querySelector("link[rel='icon']");
        favicon.href = settings.logo;
      }
      if (settings.name) document.title = settings.name + ' - Dashboard';
    },
    setSize() {
      if (window.innerWidth < 600) {
        this.nav = false;
        this.mobileNav = true;
      }
    },
    toggleAdminNav() {
      if (this.showNav) {
        this.showNav = false;
        localStorage.showNav = 'false';
      } else {
        this.showNav = true;
        localStorage.showNav = 'true';
      }
    },
    toggleNavEnlarge() {
      if (this.navEnlarge) {
        this.navEnlarge = false;
        localStorage.navEnlarge = 'false';
      } else {
        this.navEnlarge = true;
        localStorage.navEnlarge = 'true';
      }
      this.showInfo = false;
    },
    toggleInfoTab() {
      if (this.showInfo) {
        console.log('hiding');
        this.showInfo = false;
        document.removeEventListener('click', this.toggleInfoTab);
      } else {
        console.log('showing');
        this.showInfo = true;
        setTimeout(() => {
          document.addEventListener('click', this.toggleInfoTab);
        }, 100);
      }
    },
  },
  mounted() {
    document.getElementsByTagName('body')[0].classList.add('admin');
    if (localStorage.getItem('adminSession')) this.authed = true;
    this.setSize();
    window.addEventListener('resize', this.setSize);
    this.getSettings();
    document.addEventListener('settings_updated', this.getSettings);
    if (!localStorage.getItem('navEnlarge')) {
      localStorage.navEnlarge = 'true';
      this.navEnlarge = true;
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setSize);
  },
}
</script>

<style lang="scss">
@import "/src/assets/styles/variables.scss";

body.main {
  margin: 0;
  padding: 0;
}

.main {
  #app {
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: $text;
    letter-spacing: 0.8px;

    nav {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100px;
      background-image: linear-gradient(to bottom, #191b1d, #1b1d1f00);
      display: flex;
      align-items: center;
      justify-content: right;
      padding: 0 10%;
      box-sizing: border-box;
      font-size: 1.1rem;
      z-index: 10;

      img {
        position: fixed;
        top: 25px;
        left: 10%;
        height: 50px;
        cursor: pointer;
      }

      a {
        text-decoration: none;
        color: white;
        font-weight: normal;
        font-size: 0.9em;
        padding: 5px 20px;
        border-right: 1px solid white;

        &.router-link-exact-active {
          text-decoration: underline;
        }

        &:last-child {
          border-right: none;
        }

        &.join,
        &.book {
          font-weight: bold;
        }
      }
    }
  }
}

.admin {
  background-color: #f4f8f9;

  .router-view,
  #app {
    position: fixed;
    top: 0;
    right: 0;
    width: calc(100% - 81px);
    height: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: small;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #333;
    background-color: #f4f8f9;
    transition: width 0.2s;

    &.navEnlarge {
      width: calc(100% - 231px);
    }

    h1 {
      color: $colourP;
    }

    button,
    select,
    option,
    input {
      font-family: inherit;
      font-size: inherit;
    }
  }

  #header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px;

    #left {
      margin: 10px;
      font-weight: normal;
      font-size: 1.8em;
      color: #333;
    }

    #right {
      display: flex;
      align-items: center;

      select {
        background: none;
        color: #333;
        padding: 10px;
        border: solid 1px #e6e6e6;
        width: 20vw;
        max-width: 200px;
        border-radius: 5px;
        height: 42px;
        box-sizing: border-box;
        margin-left: 20px;
        background-color: white;
      }

      #weekSelect {
        width: 250px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: solid 1px #e6e6e6;
        background-color: white;
        border-radius: 5px;
      }

      p {
        margin: 0;
        color: #333;
      }

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 15px;
        box-sizing: border-box;
        height: 40px;
        transition: all 0.2s ease-in-out;
        color: #333;

        &:hover {
          cursor: pointer;
          background: #e0e0e0;
        }

        &:first-child {
          border-right: solid 1px #e6e6e6;
        }

        &:last-child {
          border-left: solid 1px #e6e6e6;
        }
      }

      b {
        box-sizing: border-box;
        padding: 10px;
        height: 40px;
        margin-left: 10px;
        color: #333;
        display: flex;
        font-size: 1.1em;
        justify-content: center;
        align-items: center;
      }

      button {
        background: none;
        padding: 10px;
        border: solid 1px #e6e6e6;
        width: 100px;
        text-align: center;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:first-child {
          border-radius: 5px 0 0 5px;
          border-right: none;
        }

        &:last-child {
          border-radius: 0 5px 5px 0;
        }

        &.selected {
          background: linear-gradient(140deg, $colourP, $colourS);
          color: white;
        }

        &:hover {
          background-color: $colourP;
          color: white;
        }
      }
    }
  }

  #adminDesktopNav {
    position: fixed;
    top: 0;
    left: 0;
    width: 50px;
    height: calc(100% - 60px);
    padding: 10px 15px;
    padding-bottom: 50px;
    overflow-y: auto;
    overflow-x: hidden;
    // background-color: #edf3f5;
    border-right: solid 1px #ccc;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    transition: width 0.2s ease-in-out;

    #logo {
      width: 90%;
      max-height: 50px;
      margin: 12px 0;
      object-fit: cover;
    }

    a {
      font-weight: bold;
      text-decoration: none;
      color: #333;
      display: block;
      width: 50px;
      height: 50px;
      margin: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      transition: background-color 0.1s ease-in-out, width 0.2s ease-in-out;
      font-size: 20px;

      &:hover {
        background-color: #cccccc30;
      }

      &.router-link-exact-active {
        background-color: #cce7ee;
      }

      img {
        width: 30px;
      }

      span {
        visibility: hidden;
        width: 0;
        opacity: 0;
        transition: visibility 0s, opacity 0.5s linear;
      }
    }

    #openNav,
    #openInfo {
      position: absolute;
      bottom: 10px;
      left: 20px;
      padding: 15px;
      font-size: medium;
      border-radius: 40px;
      width: 46px;
      box-sizing: border-box;
      color: #85888b;
      transition: all 0.2s;
      cursor: pointer;

      &:hover {
        color: $colourP;
        background-color: #ccc;
      }
    }

    #openInfo {
      right: 20px;
      left: unset;
      display: none;
    }

    #infoTab {
      position: absolute;
      bottom: 60px;
      right: 20px;
      display: flex;
      flex-direction: column;
      background-color: white;
      padding: 0 5px;
      box-shadow: 0 0 15px #ccc;
      border-radius: 5px;
      width: calc(100% - 50px);
      z-index: 2;

      a {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: #333;
        margin: 5px 0;
        padding: 0;
        font-size: 13px;
        font-weight: normal;
        width: 100%;
      }
    }

    &.navEnlarge {
      width: 200px;

      a {
        width: 200px;
        text-align: left;
        justify-content: flex-start;
        padding-left: 5px;

        i {
          width: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #85888b;
          font-size: 0.88em;
        }

        img {
          margin: 0 6px;
        }

        span {
          visibility: visible;
          width: auto;
          opacity: 1;
          font-weight: normal;
          font-size: small;
          margin-left: 10px;
          white-space: nowrap;
        }
      }

      #openInfo {
        bottom: 10px;
        display: block;
      }
    }

    #logout {
      cursor: pointer;
    }
  }

  @media (max-width: 600px) {
    #app {
      left: 0;
      width: 100%;
    }
  }
}

.fa-spinner {
  animation: spin 2s linear infinite;
}

// Loader
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 600px) {

  .router-view,
  #app {
    left: 0;
    width: 100% !important;
  }

  #openNav,
  #closeNav {
    position: absolute;
    right: 40px;
    opacity: 0;
    rotate: 90deg;
    transition: 0.2s;
    cursor: pointer;
  }

  #closeNav {
    rotate: -90deg;
  }

  #openNav.active,
  #closeNav.active {
    z-index: 3;
    opacity: 1;
    rotate: 0deg;
  }

  .admin {
    #mobileAdminNavBar {
      position: fixed;
      top: 10px;
      left: 100px;
      z-index: 100;
    }

    #mobileAdminNavDropdown {
      display: none;
      position: fixed;
      top: 50px;
      left: 0px;
      width: 100vw;
      height: 500px;
      background-color: white;
      justify-content: space-evenly;
      align-items: flex-start;
      z-index: 99;

      &.dropdownOpen {
        display: flex;
        flex-direction: column;
        padding: 20px 40px;
        box-sizing: border-box;
        box-shadow: 0 0 15px #ccc;
      }

      &::before {
        content: "";
        position: absolute;
        top: -49px;
        left: 0;
        width: 100%;
        height: 50px;
        background-color: white;
      }

      a {
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        margin-bottom: 30px;
        color: #333;
      }

      img {
        width: 50px;
        margin-right: 20px;
      }

      i {
        font-size: 1.2rem;
        width: 50px;
        margin-right: 20px;
        color: #85888b;
      }
    }

    #openNav,
    #closeNav {
      padding: 20px;
      opacity: 0;
      rotate: 90deg;
      transition: 0.2s;
      cursor: pointer;
    }

    #closeNav {
      rotate: -90deg;
    }

    #openNav.active,
    #closeNav.active {
      z-index: 3;
      opacity: 1;
      rotate: 0deg;
    }
  }
}
</style>
